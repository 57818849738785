import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { withPrefix, Link } from "../../utils"
import { map } from "lodash"
import { Col, Card, CardBody } from "reactstrap"
import { StaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

//Import Icons
import FeatherIcon from "feather-icons-react"

export default function ProfileCard(props) {
  const renderSocialLinks = links => {
    return (
      <ul className="list-unstyled social-icon team-icon mb-0 mt-4">
        {map(links, (link, link_idx) => {
          return (
            <li className="list-inline-item ml-1" key={link_idx}>
              <a
                href={link.link}
                className="rounded"
                target="_blank"
                rel="noreferrer"
              >
                <i>
                  <FeatherIcon
                    icon={link.type}
                    className="fea icon-sm fea-social"
                  />
                </i>
              </a>
            </li>
          )
        })}
      </ul>
    )
  }
  const { profile, isTransparent } = props
  return (
    <Card
      className={
        isTransparent === true
          ? "team text-center bg-transparent border-0"
          : "team text-center rounded border-0"
      }
      name="team"
      id="teambox"
    >
      <CardBody className={isTransparent === true ? "p-0" : ""}>
        <div className="position-relative">
          <GatsbyImage
            image={profile.picture.localFile.childImageSharp.gatsbyImageData}
            className="img-fluid avatar avatar-ex-large rounded-circle shadow"
            alt={profile.name}
          />
          {renderSocialLinks(profile.social_links)}
        </div>
        <div
          className={
            isTransparent === true ? "content pt-3 pb-3" : "content pt-3"
          }
        >
          <h5 className="mb-0">
            <a
              href={profile.social_links && profile.social_links[0]?.link}
              className="name text-dark"
            >
              {profile.name}
            </a>
          </h5>
          <small className="designation text-muted">{profile.title}</small>
        </div>
      </CardBody>
    </Card>
  )
}
