import { get } from "lodash"
import { FV, PMT, CUMPRINC } from "formulajs"

const vacancyLossPercent = 0.05
const annualOperatingExpenseIncrease = 0.02
const annualRevenueIncrease = 0.02

const categoryTypeMap = {
  income: ["rent"],
  expense: [
    "property_tax",
    "insurance",
    "hoa_fees",
    "utilities",
    "garbage",
    "property_management",
    "maintenance",
    "leasing_fee",
    "hoa_fee",
    "vacancy_rate",
  ],
  debt: ["mortgage_interest", "amortized_years"],
}

const DEFAULT_MORTGAGE_INTEREST = 3.8;

const dollarValueRegex = /\$((\d{1,3},)*\d+(\.\d{2})?)/;

const parsePropertyMetaDollars = (data) => {
  const match = data.match(dollarValueRegex);
  if (match) {
    return parseFloat(match[1].replace(",", ""), 10);
  } else if (!isNaN(data)) {
    return parseFloat(data, 10);
  } else {
    return 0;
  }
};

export async function createPropertyAssumptions(data) {
  let purchasePrice = 0,
    rent = 0,
    insurance = 0,
    propertyTax = 0,
    hoaDues = 0;
  if (data.price) {
    const match = data.price.match(dollarValueRegex);
    if (match) {
      purchasePrice = parseInt(match[1].replaceAll(",", ""), 10);
    }
  }

  if(data.rent){
    rent = data.rent
  }

  // const zipCode = data.zip;
  // const bedroomCount = parseInt(data.beds);
  // if (zipCode) {
  //   const response = await fetch(
  //     `https://cms.bettercapital.us/zip-infos?zip=${zipCode}`
  //   );
  //   const zipCodeData = await response.json();
  //   if (bedroomCount === 0) {
  //     rent = zipCodeData[0].studio_rent;
  //   } else if (bedroomCount === 1) {
  //     rent = zipCodeData[0].one_bedroom_rent;
  //   } else if (bedroomCount === 2) {
  //     rent = zipCodeData[0].two_bedroom_rent;
  //   } else if (bedroomCount === 3) {
  //     rent = zipCodeData[0].three_bedroom_rent;
  //   } else if (bedroomCount === 4) {
  //     rent = zipCodeData[0].four_bedroom_rent;
  //   } else if (bedroomCount > 4) {
  //     rent = zipCodeData[0].four_bedroom_rent * 1.15;
  //   }
  // }
  if (data.insurance) {
    insurance = parsePropertyMetaDollars(data.insurance);
  }
  if (data.taxes) {
    propertyTax = parsePropertyMetaDollars(data.taxes);
  }
  if (data.hoa_dues) {
    hoaDues = parsePropertyMetaDollars(data.hoa_dues);
  }
  return {
    property_tax: {
      type: "property_tax",
      value: propertyTax,
      value_type: "dollars",
      frequency: "monthly",
      display: "Property Tax"
    },
    purchase_price: {
      type: "purchase_price",
      value: purchasePrice,
      value_type: "dollars",
      display: "Purchase Price"
    },
    insurance: {
      type: "insurance",
      value: insurance,
      value_type: "dollars",
      frequency: "monthly",
      display: "Insurance Cost"
    },
    down_payment: {
      type: "down_payment",
      value: 25,
      value_type: "percent",
      display: "Down Payment"
    },
    mortgage_interest: {
      type: "mortgage_interest",
      value: DEFAULT_MORTGAGE_INTEREST,
      value_type: "percent",
      display: "Mortgage Interest"
    },
    amortized_years: {
      type: "amortized_years",
      value: 30,
      display: "Number of Years"
    },
    vacancy_rate: {
      type: "vacancy_rate",
      value: 10,
      value_type: "percent",
      display: "Vacancy"
    },
    property_management: {
      type: "property_management",
      value: (rent * 0.1).toFixed(2),
      value_type: "dollars",
      frequency: "monthly",
      display: "Property Management"
    },
    hoa_dues: {
      type: "hoa_dues",
      value: hoaDues,
      value_type: "dollars",
      frequency: "monthly",
      display: "Homeowners Association"
    },
    rent: {
      type: "rent",
      value: rent,
      frequency: "monthly",
      value_type: "dollars",
      display: "Rent"
    }
  };
}

export function getAssumptionValue(assumption, purchase_price, monthly) {
  let value = get(assumption, "value", 0)
  let value_type = get(assumption, "value_type", "percent")
  let frequency = get(assumption, "frequency", "yearly")

  let assumptionYearly =
    value_type === "percent"
      ? (purchase_price * value) / 100
      : frequency === "monthly"
      ? value * 12
      : value

  return monthly ? assumptionYearly / 12 : assumptionYearly
}

export function getAssumptionPercentValue(assumption, price) {
  let value = get(assumption, "value", 0)
  let value_type = get(assumption, "value_type", "percent")
  if (value_type === "dollars" && assumption.type !== "rent") {
    value = (value * 100) / price
  }
  return value
}
export function getMortgageAmount(assumptions) {
  let purchase_price = assumptions.find(
    assumption => assumption.type === "purchase_price"
  )
  let downPayment = assumptions.find(
    assumption => assumption.type === "down_payment"
  )
  let mortgage_interest = assumptions.find(
    assumption => assumption.type === "mortgage_interest"
  )
  let amortized_years = assumptions.find(
    assumption => assumption.type === "amortized_years"
  )

  let interest_rate = get(mortgage_interest, "value", 5)
  let years = get(amortized_years, "value", 30)
  let price = get(purchase_price, "value", 0)
  let down_payment_percent = get(downPayment, "value", 15)
  let loan_amount = price * (1 - down_payment_percent / 100)
  return -PMT(interest_rate / 1200, years * 12, loan_amount)
}

export function getNetIncome(assumptions) {
  let purchase_price_obj = assumptions.find(
    assumption => assumption.type === "purchase_price"
  )
  let purchase_price = get(purchase_price_obj, "value", 0)

  let income_assumptions = assumptions.filter(assumption =>
    categoryTypeMap.income.includes(assumption.type)
  )
  return income_assumptions.reduce((total, income) => {
    total = total + getAssumptionValue(income, purchase_price, false)
    return total
  }, 0)
}

export function getAssumptionSummary(assumptions, type) {
  let purchase_price_obj = assumptions.find(
    assumption => assumption.type === "purchase_price"
  )
  let purchase_price = get(purchase_price_obj, "value", 0)

  switch (type) {
    case "income":
      let income_assumptions = assumptions.filter(assumption =>
        categoryTypeMap.income.includes(assumption.type)
      )
      let incomeTotal = income_assumptions.reduce((total, income) => {
        total = total + getAssumptionValue(income, purchase_price, false)
        return total
      }, 0)

      return {
        label: "Net Income",
        value: incomeTotal,
      }
    case "expense":
      let expense_assumptions = assumptions.filter(assumption =>
        categoryTypeMap.expense.includes(assumption.type)
      )
      let expenseTotal = expense_assumptions.reduce((total, expense) => {
        let base_price = purchase_price
        if (
          expense.type === "property_management" ||
          expense.type === "hoa_fee" ||
          expense.type === "maintenance" ||
          expense.type === "vacancy_rate"
        ) {
          base_price = getNetIncome(assumptions)
        }
        total = total + getAssumptionValue(expense, base_price, false)
        return total
      }, 0)
      return {
        label: "Net Expenses",
        value: expenseTotal,
      }
    case "debt":
      let mortgageAmount = getMortgageAmount(assumptions)
      return {
        label: "Principal & Interest",
        value: mortgageAmount * 12,
      }
    default:
      console.error(`Unrecognized assumption type: ${type}`);
      break;
  }
}

export function getIncomeAssumptions(assumptions) {
  return assumptions.filter(assumption =>
    categoryTypeMap.income.includes(assumption.type)
  )
}
export function getExpenseAssumptions(assumptions) {
  return assumptions.filter(assumption =>
    categoryTypeMap.expense.includes(assumption.type)
  )
}

function getEquityAccrued(rate, periods, principal, year) {
  let firstPeriod = 0
  let lastPeriod = 0

  if (year === 1) {
    firstPeriod = 1
    lastPeriod = 12
  } else if (year === 2) {
    firstPeriod = 13
    lastPeriod = 24
  } else if (year === 3) {
    firstPeriod = 24
    lastPeriod = 3 * 12 + 12
  } else if (year === 4) {
    firstPeriod = 36
    lastPeriod = 4 * 12 + 12
  } else if (year === 5) {
    firstPeriod = 48
    lastPeriod = 5 * 12 + 12
  } else if (year === 10) {
    firstPeriod = 5 * 12 + 12
    lastPeriod = 10 * 12 + 12
  }

  return CUMPRINC(rate / 100, periods, principal, firstPeriod, lastPeriod, 0)
}

export function getEquityAccruedFromAssumptions(assumptions, year) {
  let purchase_price = assumptions.find(
    assumption => assumption.type === "purchase_price"
  )
  let down_payment_percent = assumptions.find(
    assumption => assumption.type === "down_payment"
  )
  let mortgage_interest = assumptions.find(
    assumption => assumption.type === "mortgage_interest"
  )
  let amortized_years = assumptions.find(
    assumption => assumption.type === "amortized_years"
  )

  const down_payment = getAssumptionValue(
    down_payment_percent,
    purchase_price.value,
    false
  )

  return -getEquityAccrued(
    mortgage_interest.value / 12,
    amortized_years.value * 12,
    purchase_price.value - down_payment,
    year
  )
}

export function getTotalOutOfPocket(assumptions) {
  let purchasePrice = assumptions.find(
    assumption => assumption.type === "purchase_price"
  )
  let downPayment = assumptions.find(
    assumption => assumption.type === "down_payment"
  )
  let setupCost = assumptions.find(
    assumption => assumption.type === "homeroom_setup_cost"
  )
  let assignmentFee = assumptions.find(
    assumption => assumption.type === "assignment_fee"
  )
  let closingCost = assumptions.find(
    assumption => assumption.type === "closing_cost"
  )
  let inspectionCost = assumptions.find(
    assumption => assumption.type === "inspections"
  )
  let rehabEstimate = assumptions.find(
    assumption => assumption.type === "rehab_estimate"
  )

  let totalOutOfPocket = 0
  totalOutOfPocket = (purchasePrice.value * downPayment.value) / 100

  if (setupCost)
    totalOutOfPocket =
      totalOutOfPocket +
      getAssumptionValue(setupCost, purchasePrice.value, false)

  if (closingCost)
    totalOutOfPocket =
      totalOutOfPocket +
      getAssumptionValue(closingCost, purchasePrice.value, false)

  if (inspectionCost)
    totalOutOfPocket =
      totalOutOfPocket +
      getAssumptionValue(inspectionCost, purchasePrice.value, false)

  if (assignmentFee)
    totalOutOfPocket =
      totalOutOfPocket +
      getAssumptionValue(assignmentFee, purchasePrice.value, false)

  if (rehabEstimate)
    totalOutOfPocket =
      totalOutOfPocket +
      getAssumptionValue(rehabEstimate, purchasePrice.value, false)

  return totalOutOfPocket
}
export function yearlyProjection(assumptionsObject, year) {
  let assumptions = []
  let assumptionsObjectKeys = Object.keys(assumptionsObject)
  assumptionsObjectKeys.forEach((assumptionKey) => assumptions.push(assumptionsObject[assumptionKey]))
  let incomeSummary = getAssumptionSummary(assumptions, "income")
  let expenseSummary = getAssumptionSummary(assumptions, "expense")
  let debtSummary = getAssumptionSummary(assumptions, "debt")
  let netIncome = incomeSummary.value
  let netExpense = expenseSummary.value

  if (year !== 0) {
    netIncome = FV(annualRevenueIncrease, year - 1, 0, -netIncome)
    netExpense = FV(annualOperatingExpenseIncrease, year - 1, 0, -netExpense)
  }
  let vacancyLoss = netIncome * vacancyLossPercent

  let netOperatingIncome = netIncome - netExpense
  let totalCashflow = netOperatingIncome - debtSummary.value
  let equityAccrued = getEquityAccruedFromAssumptions(assumptions, year)
  const totalReturn = totalCashflow + (equityAccrued || 0)

  let purchasePrice = assumptions.find(
    assumption => assumption.type === "purchase_price"
  )

  let downPayment = assumptions.find(
    assumption => assumption.type === "down_payment"
  )
  let closingCost = assumptions.find(
    assumption => assumption.type === "closing_cost"
  )

  let down_payment = getAssumptionValue(downPayment, purchasePrice.value, false)
  let closing_cost = getAssumptionValue(closingCost, purchasePrice.value, false)

  const totalROI = (totalReturn * 100) / (down_payment + closing_cost)

  return {
    purchasePrice: purchasePrice.value.toFixed(2),
    netIncome: netIncome.toFixed(2),
    netExpense: netExpense.toFixed(2),
    netDebt: debtSummary.value.toFixed(2),
    vacancyLoss: vacancyLoss.toFixed(2),
    netOperatingIncome: netOperatingIncome.toFixed(2),
    totalROI: totalROI.toFixed(2),
    totalReturn: totalReturn.toFixed(2),
    totalCashflow: totalCashflow.toFixed(2),
    equityAccrued: equityAccrued.toFixed(2),
  }
}

export function getCashFlowDetails(assumptionsObject){
  let assumptions = []
  let assumptionsObjectKeys = Object.keys(assumptionsObject)
  assumptionsObjectKeys.forEach((assumptionKey) => assumptions.push(assumptionsObject[assumptionKey]))

  let incomeSummary = getAssumptionSummary(assumptions, "income");
  let expenseSummary = getAssumptionSummary(assumptions, "expense");
  let debtSummary = getAssumptionSummary(assumptions, "debt");
  let netOperatingIncome = (
    incomeSummary.value - expenseSummary.value
  ).toFixed(2);
  let totalOutOfPocket = getTotalOutOfPocket(assumptions);
  let equityAccrued = getEquityAccruedFromAssumptions(assumptions, 1);

  let totalCashflow = netOperatingIncome - debtSummary.value;
  let cashROI = ((totalCashflow * 100) / totalOutOfPocket).toFixed(2);
  const totalReturn = totalCashflow + (equityAccrued || 0);
  const totalROI = (totalReturn * 100) / totalOutOfPocket;

  return { totalCashflow, cashROI, totalROI }
}

export function analyzeAsset(data) {
  return {
    current: yearlyProjection(data, 1),
    future: {
      2: yearlyProjection(data, 2),
      5: yearlyProjection(data, 5),
      10: yearlyProjection(data, 10),
    },
  }
}
