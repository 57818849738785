import React from "react"
import { Link, markdownify } from "../../utils"
import { Container, Row, Col, Breadcrumb, BreadcrumbItem } from "reactstrap"
import homeImg from "../../assets/images/bettercapital-dashboard.png"
import imgbg from "../../assets/images/task/bg.png"
import { StaticImage } from "gatsby-plugin-image"

import Loadable from "@loadable/component"
const SubscribeForm = Loadable(() => import("../sections/SubscribeForm"))

const PageBreadcrumb = props => {
  const getPathItems = source => {
    switch (source) {
      case "about":
        return [
          { id: 1, name: "Home", link: "/" },
          { id: 2, name: "About", link: "/about" },
        ]
      case "partners":
        return [
          { id: 1, name: "Home", link: "/" },
          { id: 2, name: "Partners", link: "/partners" },
        ]
      case "events":
        return [
          { id: 1, name: "Home", link: "/" },
          { id: 2, name: "Events", link: "/events" },
        ]
      case "terms":
        return [
          { id: 1, name: "Home", link: "/" },
          { id: 2, name: "Terms", link: "/terms" },
        ]
      case "privacy":
        return [
          { id: 1, name: "Home", link: "/" },
          { id: 2, name: "Privacy", link: "/privacy" },
        ]
      case "cookie-policy":
        return [
          { id: 1, name: "Home", link: "/" },
          { id: 2, name: "Cookie Policy", link: "/cookie-policy" },
        ]
      case "counties":
        return [
          { id: 1, name: "Home", link: "/" },
          { id: 2, name: "Counties", link: "/counties" },
        ]
      case "opportunities":
        return [
          { id: 1, name: "Home", link: "/" },
          { id: 2, name: "opportunities", link: "/opportunities" },
        ]
      case "markets":
        return [
          { id: 1, name: "Home", link: "/" },
          { id: 2, name: "Markets", link: "/markets" },
        ]
      case "counties":
        return [
          { id: 1, name: "Home", link: "/" },
          { id: 2, name: "Counties", link: "/counties" },
        ]
      default:
      case "blogs":
        return [
          { id: 1, name: "Home", link: "/" },
          { id: 2, name: "Blogs", link: "/blogs" },
        ]
    }
  }
  const {
    page: {
      title,
      subtitle,
      showBreadcrumb,
      detailPage,
      source,
      subscribe,
      slug,
    },
    children,
    isHomePage,
  } = props
  let pathItems
  if (source) pathItems = getPathItems(source)
  else pathItems = getPathItems(slug)

  if (detailPage)
    pathItems.push({
      id: 3,
      name: `${source} Detail`,
    })

  const length = pathItems.length

  if (showBreadcrumb) {
    return (
      <React.Fragment>
        <section
          className="bg-profile d-table w-100 bg-primary"
          style={{ background: `url(${imgbg}) center center` }}
        >
          <Container>
            <Row className="justify-content-center">
              <Col lg="12" className="text-center text-white title-dark">
                <div className="page-next-level">
                  {title ? (
                    <h4 className="text-white title-dark"> {title}</h4>
                  ) : null}
                  {subtitle && (
                    <p className="text-white para-desc mb-0 mx-auto">
                      {markdownify(subtitle)}
                    </p>
                  )}
                  {subscribe && (
                    <SubscribeForm section={subscribe} isHero={true} />
                  )}
                  {children}
                  <div className="page-next">
                    <nav className="d-inline-block">
                      <Breadcrumb
                        aria-label="breadcrumb"
                        listClassName="bg-white rounded shadow mb-0"
                      >
                        {pathItems.map((item, key) =>
                          item.id !== length ? (
                            <BreadcrumbItem key={key}>
                              <Link
                                to={item.link}
                                className="text-uppercase font-weight-bold text-dark mr-1"
                              >
                                {item.name}
                              </Link>
                            </BreadcrumbItem>
                          ) : (
                            <BreadcrumbItem
                              key={key}
                              active
                              aria-current="page"
                            >
                              {item.name}
                            </BreadcrumbItem>
                          )
                        )}
                      </Breadcrumb>
                    </nav>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <div className="position-relative">
          <div className="shape overflow-hidden text-white">
            <svg
              viewBox="0 0 2880 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                fill="currentColor"
              ></path>
            </svg>
          </div>
        </div>
      </React.Fragment>
    )
  } else {
    return (
      <React.Fragment>
        <section
          className="bg-header d-table w-100 bg-primary"
        />
      </React.Fragment>
    )
  }
}

export default PageBreadcrumb
